import React from "react";

const ExternalLink = ({ to, target = "_blank", className = "", children }) => {
  return (
    <a href={to} target={target} rel={target === "_blank" ? "noopener noreferrer" : undefined} className={className}>
      {children}
    </a>
  );
};

export default ExternalLink;
