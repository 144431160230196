import React, { useContext } from 'react';
import { MenuContext } from '../../context';
import NewWindow from '../../assets/new_window.inline.svg';
import ExternalLink from '../external-link/external-link.component';

const LiveChatBtn = () => {
  const menuContext = useContext(MenuContext);

  return (
    <span><ExternalLink to='https://www.hsbc.co.uk/mortgages/intermediaries/help/'
      className='live-chat-container'
      target='_blank'
      inert={menuContext.isMenuOpen ? 'true' : null}
      data-tealium
    >
      Chat with us
      <NewWindow />
    </ExternalLink>
    </span>
  );
};

export default LiveChatBtn;