import React, { useState, useRef, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { MOBILE_SCHEME } from "./mobile.utils";
import ExternalLink from "../external-link/external-link.component";
import { MenuContext } from "../../context";

const MOBILE_MENU_BODY_CLASS = "mobile-menu-open";

const MobileMenu = ({ isOpen, setIsOpen }) => {
  const [isClosing, setIsClosing] = useState(false);
  const menu = useRef(null);
  const scrollTargetElement = useRef(null);

  const setAnimation = () => {
    if (isClosing) {
      setIsClosing(false);
      setIsOpen(false);
      setFocusOnMenuButton();
    }
  };

  useEffect(() => {
    const target = scrollTargetElement.current;
    if (isOpen) {
      document.body.classList.add(MOBILE_MENU_BODY_CLASS, isOpen);
      disableBodyScroll(target);
      document.querySelector(".mobile-menu__scroll-wrapper a").focus();
    } else {
      document.body.classList.remove(MOBILE_MENU_BODY_CLASS, isOpen);
      enableBodyScroll(target);
    }
    return () => {
      clearAllBodyScrollLocks(target);
      document.body.classList.remove(MOBILE_MENU_BODY_CLASS);
    };
  }, [isOpen]);

  const setFocusOnMenuButton = () => {
    const menuButton = document.querySelector(".mobile-menu-button");
    if (menuButton) {
      setTimeout(() => menuButton.focus(), 0);
    }
  };

  return (
    <nav
      className={`mobile-menu 
            ${(isOpen && !isClosing && "mobile-menu--open") || ""}
            ${(isClosing && "mobile-menu--closed") || ""}
            `}
      ref={menu}
      onAnimationEnd={setAnimation}
    >
      <MenuContext.Consumer>
        {({ isMenuOpen, toggleMenu }) => (
          <>
            <button
              className={"general-btn close-btn general-btn--square"}
              type="button"
              onClick={() => {
                setIsClosing(true);
                toggleMenu();
                setFocusOnMenuButton();
              }}
            >
              Close menu
            </button>

            <div ref={scrollTargetElement} className="mobile-menu__scroll-wrapper">
              <ul>
                {MOBILE_SCHEME.map(({ title, link, isLink, isBlank }, index) => (
                  <li key={title} className={`${index === MOBILE_SCHEME.length - 1 ? "last-item" : ""}`}>
                    {isLink ? (
                      <Link
                        className="navigation-item"
                        to={link}
                        target="_self"
                        onClick={() => {
                          setIsClosing(true);
                          toggleMenu();
                          setFocusOnMenuButton();
                          clearAllBodyScrollLocks();
                          navigate(link);
                        }}
                      >
                        {title}
                      </Link>
                    ) : (
                      <Link
                        className="navigation-item"
                        to={link}
                        target={isBlank ? "_blank" : "_self"}
                        onClick={() => {
                          setIsClosing(true);
                          toggleMenu();
                          setFocusOnMenuButton();
                          clearAllBodyScrollLocks();
                          navigate(link);
                        }}
                      >
                        {title}
                      </Link>
                    )}
                  </li>
                ))}
                <li className="borderless borderless--padding">
                  <Link
                    to="/calculator"
                    target="_blank"
                    onClick={() => {
                      setIsClosing(true);
                      toggleMenu();
                      setFocusOnMenuButton();
                      clearAllBodyScrollLocks();
                    }}
                  >
                    Launch calculators
                  </Link>
                </li>
                <li className="borderless">
                  <Link
                    to="/packaging-requirements"
                    onClick={() => {
                      setIsClosing(true);
                      toggleMenu();
                      setFocusOnMenuButton();
                      clearAllBodyScrollLocks();
                    }}
                  >
                    Packaging requirements
                  </Link>
                </li>
                <li className="colored">
                  <ExternalLink
                    to="https://www.hsbc.co.uk/mortgages/intermediaries/help/"
                    target="_blank"
                    onClick={() => {
                      setIsClosing(true);
                      toggleMenu();
                      setFocusOnMenuButton();
                      clearAllBodyScrollLocks();
                    }}
                  >
                    Live chat
                  </ExternalLink>
                </li>
                <li className="colored">
                  <Link
                    to="/frequently-asked-questions"
                    onClick={() => {
                      setIsClosing(true);
                      toggleMenu();
                      setFocusOnMenuButton();
                      clearAllBodyScrollLocks();
                    }}
                  >
                    FAQs
                  </Link>
                </li>
                <li className="colored">
                  <ExternalLink
                    to="http://www.intermediaries.hsbc.co.uk/helpdesk.html"
                    target="_blank"
                    onClick={() => {
                      setIsClosing(true);
                      toggleMenu();
                      setFocusOnMenuButton();
                      clearAllBodyScrollLocks();
                    }}
                  >
                    Help desk
                  </ExternalLink>
                </li>
              </ul>
            </div>
          </>
        )}
      </MenuContext.Consumer>
    </nav>
  );
};

export default MobileMenu;
